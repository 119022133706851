body {
  margin: 0;
  font-family: "Almarai", sans-serif;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.font {
  font-family: "Almarai", sans-serif;
  background-color: #f9f9f9;
}

.hieght-search {
  height: 80vh;
}

.logo {
  height: 46px;
  width: 50px;
}

.login-img {
  height: 20px;
  width: 20px;
}

.nav-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}

.sub-tile {
  color: #272727;
  font-family: Almarai;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.slider-background {
  background: linear-gradient(173.68deg, #ffedf5 0%, #ffd5e7 100%);
  height: "296px";
  width: 294.26px;
}

.slider-background2 {
  background: linear-gradient(174.76deg, #d3deff 0%, #a8baff 100%);
  height: "296px";
  width: 294.26px;
}

.slider-background3 {
  background: linear-gradient(176.69deg, #f8f0d5 0%, #efdcab 100%);
  height: "296px";
  width: 294.26px;
}

.slider-background4 {
  background: linear-gradient(175.24deg, #b2e8f0 0%, #7bccdc 100%);
  height: "296px";
  width: 294.26px;
}

.slider-title {
  color: #915970;
  font-family: Almarai;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.slider-text {
  color: #915970;
  font-family: Almarai;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 53px;
  text-align: center;
}

.allCard {
  position: relative;
  height: 130px;
  width: 130px;
}

.categoty-card {
  height: 140px;
  width: 100%;
  border-radius: 50%;
  opacity: 0.3;
}

.categoty-card-img {
  max-width: 110px;
  max-height: 100px;
  position: absolute;
  top: 20%;
  left: 10%;
}

.categoty-card-text {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* products card style */
.card-title {
  color: #555550;
  font-family: Almarai;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.card-price {
  color: #272727;
  font-family: Almarai;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.card-currency {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.card-rate {
  color: #ffc107;
  font-family: Almarai;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

/* end of card style */
.shopping-now {
  padding: 5px;
  height: 35px;
  width: 90px;
  color: #272727;
  text-decoration: none;
  text-align: center;
  border: 1px solid #272727;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 800px) {
  .shopping-now {
    height: 30px;
    width: 60px;
    font-size: 14px;
  }
}

.shopping-now:hover {
  background-color: #272727;
  border: 1px solid #272727;
  color: #f9f9f9;
  border-radius: 14px;
  cursor: pointer;
}

/* discount setion */
.discount-backcolor {
  height: 130px;
  border-radius: 8px;
  background: radial-gradient(circle, #767676 0%, #4d4f50 100%, #494c4d 100%);
}

.discount-title {
  color: #ffffff;
  font-family: Cairo;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
}

.dicount-img {
  max-height: 130px;
  max-width: 80%;
}

/* end discount */
/* foter */
.footer-background {
  background-color: #ffffff;
}

.footer {
  width: 100%;
}

.footer-shroot {
  color: #979797;
  font-family: Almarai;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
}

.footer-phone {
  color: #979797;
  font-family: "Almarai";
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}

/* end footer */
/* card filter style */
.search-count-text {
  color: #979797;
  font-family: Almarai;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.card-filter {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  width: 170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-filter-item {
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}

.card-filter-item:hover {
  background-color: #272727;
  color: #ffffff;
}

/* end card filter */
/* products details page */
.product-gallary-card {
  margin: 30px 0;
  height: 400px !important;
  width: 300px !important;
  border-radius: 29px;
  background-color: #ffffff;
}
@media (max-width: 800px) {
  .product-gallary-card {
    width: 200px !important;
    height: 200px !important;
    margin: 30px auto !important;
  }
}

.cat-text {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  text-align: right;
}

.cat-title {
  color: #555550;
  font-family: Almarai;
  font-size: 14px;
  font-weight: normal;
  text-align: right;
}

.cat-rate {
  color: #ffc107;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

.barnd-text {
  color: #555550;
  font-family: "Almarai";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.color {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.product-description {
  color: #555550;
  font-family: Cairo;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
}

.product-price {
  height: 46px;
  width: 150px;
  text-align: center;
  border-radius: 9px;
  color: #555550;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  background-color: #ffffff;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 10px 0;
}
@media (max-width: 800px) {
  .product-price {
    font-size: 14px !important;
    height: 30px;
  }
}

.product-cart-add {
  cursor: pointer;
  text-align: center;
  height: 45px;
  border-radius: 9px;
  border: none;
  background-color: #272727;
  color: #ffffff;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: normal;
}

.product-cart-add:hover {
  color: #bdbaba !important;
}

/* products gallary slide style */
.image-gallery-slide {
  width: 372px;
  height: 470px;
  border-radius: 29px;
}

/* end gallary */
/* end products page */
/* pagination */
.page-link {
  background-color: #f9f9f9 !important;
  color: #272727 !important;
  border-radius: 10px !important;
  border: solid 1px 272727 !important;
}

.page-link:hover {
  background-color: #272727 !important;
  color: white !important;
  border: solid 1px 272727 !important;
}

/* end pagination */
/* category header */
.cat-header {
  height: 100%;
  background-color: white;
}

.cat-text-header {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 10px;
  margin-left: 17px;
  cursor: pointer;
}

.cat-text-header:hover {
  color: #555550;
}

/* end categoery header */
/* login and register style */
.user-input {
  box-sizing: border-box;
  height: 37px;
  width: 375px;
  border: 1px solid #979797;
  border-radius: 8px;
}
@media (max-width: 800px) {
  .user-input {
    width: 100%;
  }
}

@media (max-width: 800px) {
  * {
    font-size: 16px !important;
  }
}

@media (max-width: 800px) {
  .carousel-inner {
    max-height: 250px;
  }
  .subtitle {
    padding: 0 !important;
    margin: 10px 0;
    align-items: center;
  }
}
@media (max-width: 800px) {
  .slider-background > div, .slider-background2 > div, .slider-background3 > div, .slider-background4 > div {
    width: 100% !important;
    width: 100% !important;
  }
  .slider-background > div img, .slider-background2 > div img, .slider-background3 > div img, .slider-background4 > div img {
    width: 100px !important;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.title-login {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}

.btn-login {
  height: 46px;
  width: 374px;
  border-radius: 9px;
  border: none;
  color: white;
  background-color: #272727;
}
@media (max-width: 800px) {
  .btn-login {
    width: 200px;
    height: 30px;
  }
}

.btn-login:hover {
  color: rgb(206, 204, 204);
}

/* end login and register */
/* cart page */
.cart-title {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}

.cart-item-body {
  height: 229px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

.copon-input {
  box-sizing: border-box;
  height: 46px;
  width: 90%;
  border: 1px solid #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}

.copon-btn {
  cursor: pointer;
  border: none;
  height: 46px;
  padding-top: 5px;
  border-radius: 5px !important;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px !important;
  border-radius: 0 !important;
  background-color: #272727;
  color: white !important;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}

.cart-checkout {
  height: 200px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

/* cart end */
/* admin style  */
.sidebar {
  height: 660px;
  width: 120%;
  border-radius: 21px;
  background-color: #ffffff;
  white-space: nowrap;
}
@media (max-width: 800px) {
  .sidebar a > div {
    white-space: pre-line;
    font-size: 12px !important;
  }
}

.admin-side-text {
  color: #272727;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.admin-side-text:hover {
  background-color: #272727;
  color: white;
  font-size: 14px;
}

.admin-content-text {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
  display: flex;
  align-items: center;
}

.item-delete-edit {
  color: #979797;
  font-family: "Almarai";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  cursor: pointer;
}

.text-form {
  color: #979797;
  font-family: "Almarai";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.input-form {
  height: 37px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.input-form-area {
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.select {
  box-sizing: border-box;
  height: 40px;
  color: #979797;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
}

.modal-footer button {
  width: 80px !important;
  height: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-right: 10px !important;
}

.btn-save {
  border: none;
  color: white;
  height: 46px;
  width: 150px;
  border-radius: 9px;
  background-color: #272727;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 800px) {
  .btn-save {
    font-size: 14px !important;
    width: 130px;
    height: 30px;
  }
}

.btn-save:hover {
  color: rgb(164, 164, 164);
}

.btn-a {
  border: none;
  color: white;
  height: 45px;
  border-radius: 9px;
  background-color: #272727;
}

.btn-a:hover {
  color: rgb(164, 164, 164);
}

/* end admin  */
/* rates  */
.rate-container {
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.rate-count {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}

.rate-name {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.rate-description {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

/* end rate */
/* user order */
.user-order {
  height: 100%;
  width: 80% !important;
  margin: auto !important;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.order-title {
  color: #272727;
  font-family: "Almarai";
  font-size: 16px;
  font-weight: bold;
}

.stat {
  color: #979797;
  font-family: Cairo;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
}

/* end user order */
/* user Address */
.user-address-card {
  height: 151px;
  width: 100%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.btn-add-address {
  border: none;
  height: 50px;
  width: 100%;
  color: white;
  border-radius: 9px;
  background-color: #272727;
  margin-top: 30px;
}
@media (max-width: 800px) {
  .btn-add-address {
    height: 40px;
    width: 140px;
    font-size: 14px;
  }
}

/* user address */
/* user card data */
.user-data {
  height: 270px;
  width: 100% i !important;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

/* end user data */
/* filter side style */
.filter-title {
  color: #555550;
  font-family: "Almarai";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.filter-sub {
  color: #555550;
  font-family: "Almarai";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

/* end filter */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 80px);
  width: 100%;
  height: 100%;
}

.not {
  font-size: 1.25rem;
  margin-top: 100px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.edite3 {
  width: 46px;
  height: 46px;
  text-align: center;
  margin: 0 !important;
}

.crud i {
  margin-left: 20px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 24px;
}

.crud i:hover {
  opacity: 0.8;
  transition: 0.5s;
}

.user-address-card {
  height: 130px;
  margin-bottom: 50px;
}

.dropdown-toggle {
  white-space: nowrap;
  margin: 20px 0;
  font-size: 22px;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
  position: absolute;
  top: 67px;
  box-shadow: 1px 1px 2px cadetblue;
}

.palance > div > span {
  color: red;
  text-decoration: line-through;
  font-size: 14px !important;
}

.palance1 {
  color: red;
  text-decoration: line-through;
}

.user-address-card {
  height: auto !important;
  margin-bottom: 50px;
}

.edit4 {
  padding: 5px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.admin-content-text {
  margin-bottom: 20px;
}

.admin-content-text i {
  margin-right: 30px;
  font-size: 30px;
  animation: 4s up-down linear infinite;
  position: relative;
  cursor: pointer;
}

@keyframes up-down {
  0%, 20%, 40%, 60%, 80%, 100% {
    top: 0;
    color: #494c4d;
  }
  10%, 30%, 50%, 70%, 90% {
    top: -3px;
    color: #cdd7fd;
  }
}
.boxAll i {
  margin-right: 10px;
  cursor: pointer;
}

.OrderCash {
  display: flex;
  align-items: center;
}

.OrderCash2 div {
  margin: 10px;
  border-radius: 10px;
  color: white !important;
  background-color: #24282c;
  padding: 10px;
  width: 120px !important;
  white-space: nowrap;
}

.OrderCash1 {
  display: flex;
  align-items: center;
}
.OrderCash1 .stat {
  white-space: nowrap;
}
.OrderCash1 div {
  font-size: 16px !important;
}

@media (max-width: 800px) {
  .CardProd {
    overflow: hidden;
    display: flex;
    margin: auto;
    justify-content: center;
  }
  .CardProd .CardProd1 {
    width: 250px;
    margin-bottom: 20px;
    margin: auto;
  }
  .CardProd .cover1 {
    margin: auto;
  }
  .CardProd .imgProd {
    width: 150px !important;
    height: 150px !important;
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .discount-title {
    font-size: 14px !important;
  }
  .cardBrand {
    margin: auto;
  }
  .cardBrand .img2 {
    width: 80% !important;
    margin: auto;
  }
  .Nav1 input {
    height: 40px !important;
  }
  .profile input {
    height: 30px;
  }
  .profile input::-moz-placeholder {
    font-size: 12px !important;
  }
  .profile input::placeholder {
    font-size: 12px !important;
  }
  .addAddress input, .addAddress textarea {
    width: 80%;
    margin-right: 20px;
  }
  .addAddress input::-moz-placeholder, .addAddress textarea::-moz-placeholder {
    font-size: 14px;
  }
  .addAddress input::placeholder, .addAddress textarea::placeholder {
    font-size: 14px;
  }
  .addAddress textarea {
    height: 80px;
  }
  .nameCart {
    font-size: 12px !important;
  }
  .colorCart {
    width: 20px !important;
    height: 20px !important;
  }
  .remove img {
    width: 20px;
    height: 20px;
  }
  .checkout button {
    height: 30px;
    font-size: 12px !important;
  }
  .checkout input {
    height: 30px;
  }
  .OrderCash {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }
  .OrderCash .OrderCash1 {
    display: flex !important;
    align-items: center !important;
  }
  .OrderCash .OrderCash1 .stat {
    white-space: nowrap;
  }
  .OrderCash .OrderCash1 div {
    font-size: 12px !important;
  }
  .OrderCash .OrderCash2 div {
    margin: 10px;
    position: relative;
    left: -50px;
    border-radius: 10px;
    color: white !important;
    background-color: #24282c;
    padding: 10px;
    width: 120px !important;
    white-space: nowrap;
  }
  .review {
    width: 100% !important;
    margin: 30px 0;
    overflow: hidden;
  }
  .review1 {
    width: 100% !important;
  }
  .review1 > div {
    font-size: 14px !important;
  }
  .addreview textarea {
    height: 40px;
  }
  .addreview .product-cart-add {
    font-size: 14px !important;
    height: 35px;
  }
  .img3 {
    box-shadow: 4px 3px 8px lightgray, -4px -3px 8px lightgray;
    height: 250px !important;
  }
  .img3 i {
    padding: 10px 10px 0 0;
    font-size: 25px !important;
  }
  .img3 img {
    height: 130px !important;
    width: 100% !important;
    -o-object-fit: contain;
       object-fit: contain;
    padding: 0 10px;
  }
  .footer1 div {
    font-size: 12px !important;
    white-space: nowrap !important;
  }
  .footer1 div:hover {
    text-decoration: underline;
  }
  .footer2 p {
    margin-top: 3px;
    font-size: 12px !important;
  }
}
.ImgPrev {
  width: 100%;
  height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 10px 10px;
  box-shadow: 4px 3px 8px lightgray, -4px -3px 8px lightgray;
}

.img3 {
  box-shadow: 4px 3px 8px lightgray, -4px -3px 8px lightgray;
  height: 270px !important;
}
.img3 .card-title {
  height: 20px;
  overflow: hidden;
}
.img3 img {
  width: 100% !important;
  height: 150px !important;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 0 10px;
}
.img3 i {
  padding: 10px 10px 0 0;
  font-size: 25px !important;
}

.push {
  align-items: center;
  display: flex;
}
.push .btn-save {
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 14px;
}
@media (max-width: 800px) {
  .push .btn-save {
    font-size: 10px !important;
  }
}

.Nav {
  height: 70px;
}

@media (max-width: 573px) {
  .navbar-collapse {
    background-color: #24282c;
  }
  .navbar-collapse input {
    width: 80% !important;
    margin: 10px auto !important;
    border: 1px solid rgb(0, 0, 0) !important;
  }
  .navbar-collapse a {
    color: black !important;
  }
  .navbar-collapse .navbar-nav {
    align-items: center;
    justify-content: flex-end;
  }
}
.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}/*# sourceMappingURL=index.css.map */