.font {
  padding: 0 !important;
  margin: 0 !important;
}


@media (max-width: 800px) {
  .form .user-input {
    width: 200px !important;
  }
}/*# sourceMappingURL=style.css.map */